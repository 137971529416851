export const environment = {
  production: false,
  mapboxKey:
    "pk.eyJ1IjoidW5vc21hcGJveCIsImEiOiJjbDN1YXVhMGQwMnQ4M2pvMjh5ODc5ZmVjIn0.KIebxWVkrwg84yaNmae37A",
  unosApiBase:
    "https://app-shipment-tracking-test.cloud.unos.org/api-proxy/shipment-tracking/v1",
  unosLoginApiBase:
    "https://app-shipment-tracking-test.cloud.unos.org/api/access",
  gegoApiBase: "https://app-shipment-tracking-test.cloud.unos.org/gego-proxy",
  extraHeaders: {},
  retryConfig: {
    retryableHttpResponseCodes: [408, 429, 500, 501],
    delay: 100,
    attempts: 1,
  },
  AppInsightsConnectionString:
    "InstrumentationKey=7beb1207-cf37-4b53-9b38-6ffeecc84356;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/",
  tickRate: 5 * 60 * 1000, // 5 minutes
  lookbackDays: 2,
};
